






























































































































/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Vue } from 'vue-property-decorator'
import moment from 'moment'
import Table from '@/components/Table/index.vue'
import Pagination from '@/components/Pagination/index.vue'
import ImgView from '@/components/ImgView/index.vue'
import RemarkModal from '@/views/common/remark-modal.vue'
import InfoModal from './info-modal.vue'
import { DatePicker } from 'element-ui'
Vue.use(DatePicker)

@Component({
  components: {
    Table,
    Pagination,
    ImgView,
    RemarkModal,
    InfoModal
  }
})
export default class extends Vue {
  $constants: any
  carInfo = {
    carNo: '',
    avgDuration: '',
    total: '',
    totalDuration: '',
    latestEnterTime: '',
    latestDuration: ''
  }
  listLoading = false
  tableHeader = [
    {
      key: 'enterImg',
      name: '入场照片',
      width: '10%',
      isSpecial: true
    },
    {
      key: 'carNo',
      name: '车牌号',
      width: '10%'
    },
    // {
    //   key: 'houseInfo',
    //   name: '预约信息',
    //   width: '10%',
    //   isSpecial: true
    // },
    {
      key: 'enterTime',
      name: '进入时间',
      width: '14%'
    },
    {
      key: 'enterPosition',
      name: '位置',
      width: '10%'
    },
    {
      key: 'leaveTime',
      name: '离开时间',
      width: '14%'
    },
    {
      key: 'leavePosition',
      name: '位置',
      width: '10%'
    },
    {
      key: 'remark',
      name: '备注',
      width: '8%',
      remark: true
    },
    {
      key: 'opt',
      name: '操作',
      width: '8%',
      isSpecial: true
    }
  ]
  tableData: any = []
  layout = 'total, sizes, prev, pager, next, jumper'
  total = 0
  listQuery = {
    page: 1,
    limit: 15
  }
  imgUrl = ''
  openWhichModal = ''
  remarkLoading = false
  carId = 0
  carRemark = ''
  // 预约访客Id
  reserveId = -1

  created() {
    this.getCarInfo()
    this.getCarList()
  }

  // 获取访客车辆列表
  getCarList() {
    this.listLoading = true
    this.$api.visitor
      .getCarHistoryList({
        current: this.listQuery.page,
        size: this.listQuery.limit,
        carNo: this.$route.query.carNo
      })
      .then((res: any) => {
        this.listLoading = false
        const data = res.data
        this.tableData = data.data
        this.total = data.total
      })
      .catch(() => {
        this.listLoading = false
      })
  }

  // 获取访客车辆信息
  getCarInfo() {
    this.$api.visitor
      .getCarInfo({
        carNo: this.$route.query.carNo
      })
      .then((res: any) => {
        const data = res.data.data
        this.carInfo = {
          carNo: data.carNo || '',
          avgDuration: this.transform(data.avgDuration) || '',
          total: data.total || '',
          totalDuration: this.transform(data.totalDuration) || '',
          latestEnterTime: data.latestEnterTime || '',
          latestDuration: this.transform(data.latestDuration) || ''
        }
      })
  }

  // 添加备注
  addRemark(remark: string) {
    this.remarkLoading = true
    this.$api.visitor
      .addCarRemark({
        id: this.carId,
        remark
      })
      .then((res: any) => {
        this.remarkLoading = false
        const data = res.data
        if (data.success) {
          this.$message.success('添加备注成功')
          this.getCarList()
          this.closeDialog()
        } else {
          this.$message.error(data.msg)
        }
      })
      .catch(() => {
        this.remarkLoading = false
        this.$message.error('添加备注失败')
      })
  }

  // 把秒转换成时分秒
  transform(seconds: number) {
    const duration = moment.duration(seconds, 'second')
    const hour = duration.hours()
    const min = duration.minutes()
    const second = duration.seconds()
    const res =
      (hour < 10 ? `0${hour}` : hour) +
      ':' +
      (min < 10 ? `0${min}` : min) +
      ':' +
      (second < 10 ? `0${second}` : second)
    return res
  }

  // 批量导出
  doExport() {
    // TODO
  }

  // 查看图片
  viewImg(url: string) {
    this.imgUrl = url
  }

  // 关闭图片查看
  closeImg() {
    this.imgUrl = ''
  }

  // 关闭对话框
  closeDialog() {
    this.openWhichModal = ''
  }
  // 操作对话框后统一回调
  afterOpt(data: any) {
    this.addRemark(data)
  }

  // 返回
  goback() {
    this.$router.push('/visitors/car-list')
  }
}
