var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"hostory"},[_c('div',{staticClass:"list"},[_c('div',{staticClass:"top"},[_c('i',{staticClass:"el-icon-arrow-left"}),_c('div',{staticClass:"back",on:{"click":_vm.goback}},[_vm._v("返回")])]),_vm._m(0),_c('div',{staticClass:"info-group"},[_c('div',{staticClass:"info-item"},[_c('div',{staticClass:"info"},[_c('span',{staticClass:"label"},[_vm._v("车牌号：")]),_c('span',{staticClass:"value"},[_vm._v(_vm._s(_vm.carInfo.carNo))])]),_c('div',{staticClass:"info"},[_c('span',{staticClass:"label"},[_vm._v("到访次数：")]),_c('span',{staticClass:"value"},[_vm._v(_vm._s(_vm.carInfo.total)+"次")])])]),_c('div',{staticClass:"info-item"},[_c('div',{staticClass:"info"},[_c('span',{staticClass:"label"},[_vm._v("总到访时长：")]),_c('span',{staticClass:"value"},[_vm._v(_vm._s(_vm.carInfo.totalDuration))])]),_c('div',{staticClass:"info"},[_c('span',{staticClass:"label"},[_vm._v("平均到访时长：")]),_c('span',{staticClass:"value"},[_vm._v(_vm._s(_vm.carInfo.avgDuration))])])]),_c('div',{staticClass:"info-item"},[_c('div',{staticClass:"info"},[_c('span',{staticClass:"label"},[_vm._v("最近到访时间：")]),_c('span',{staticClass:"value"},[_vm._v(_vm._s(_vm.carInfo.latestEnterTime))])]),_c('div',{staticClass:"info"},[_c('span',{staticClass:"label"},[_vm._v("最近到访时长：")]),_c('span',{staticClass:"value"},[_vm._v(_vm._s(_vm.carInfo.latestDuration))])])])]),_vm._m(1),_c('Table',{attrs:{"pageInfo":_vm.listQuery,"loading":_vm.listLoading,"header":_vm.tableHeader,"content":_vm.tableData,"height":"90"},scopedSlots:_vm._u([{key:"enterImg",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"pic",style:(("background-image: url(" + (_vm.$constants.common.imgPrefix +
              item.enterImg) + ")")),on:{"click":function($event){return _vm.viewImg(_vm.$constants.common.imgPrefix + item.enterImg)}}})]}},{key:"houseInfo",fn:function(ref){
              var item = ref.item;
return [(item.reserveId)?_c('span',{staticClass:"opt",on:{"click":function($event){_vm.openWhichModal = 'info'
            _vm.reserveId = item.reserveId}}},[_vm._v(" "+_vm._s(item.houseInfo)+" ")]):_c('span',[_vm._v("无")])]}},{key:"opt",fn:function(ref){
            var item = ref.item;
return [_c('div',{staticClass:"opt"},[_c('p',{on:{"click":function($event){_vm.openWhichModal = 'remark'
              _vm.carId = item.id
              _vm.carRemark = item.remark}}},[_vm._v(" 添加备注 ")])])]}}])}),_c('div',{staticClass:"pagination"},[_c('pagination',{attrs:{"total":_vm.total,"page":_vm.listQuery.page,"limit":_vm.listQuery.limit,"layout":_vm.layout},on:{"update:page":function($event){return _vm.$set(_vm.listQuery, "page", $event)},"update:limit":function($event){return _vm.$set(_vm.listQuery, "limit", $event)},"pagination":_vm.getCarList}})],1)],1),(_vm.imgUrl !== '')?_c('ImgView',{attrs:{"url":_vm.imgUrl},on:{"close":_vm.closeImg}}):_vm._e(),_c('RemarkModal',{attrs:{"isShow":_vm.openWhichModal === 'remark',"loading":_vm.remarkLoading,"reason":_vm.carRemark},on:{"optSuccess":_vm.afterOpt,"close":_vm.closeDialog}}),_c('InfoModal',{attrs:{"isShow":_vm.openWhichModal === 'info',"id":_vm.reserveId},on:{"optSuccess":_vm.afterOpt,"close":_vm.closeDialog}})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"title"},[_c('div',{staticClass:"block"}),_c('div',{staticClass:"name"},[_vm._v("访客车辆信息")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"title"},[_c('div',{staticClass:"block"}),_c('div',{staticClass:"name"},[_vm._v("到访记录")])])}]

export { render, staticRenderFns }